import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  Text,
  useMediaQuery,
  useStatStyles,
  useToast,
  VStack
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaArrowLeft, FaDownload, FaPlay } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import SharedComponents from 'shared-components'
import colorTheif from 'colorthief'
import { HomeComponents } from '../home/components'
import { setCurrentMusic } from '../../../redux/slices/musicSlice'
import * as FirebaseFunctions from 'firebase/functions'
import { setAppState } from '../../../redux/slices/appSlice'

export default () => {
  const [isMobile] = useMediaQuery('(max-width: 800px)')
  const musicList = useSelector(state => state.music.list)
  const user_id = useSelector(state => state.user?.id)
  const appState_singer_id = useSelector(state => state.app.singer_id)
  const { song_id } = useParams()
  const singer_id = useParams()?.singer_id || appState_singer_id
  const [song, setSong] = useState()
  const singerProfile = useSelector(state => state.app.singerProfile)
  const [dominantColor, setDominantColor] = useState({
    original: null,
    light: null
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const firebaseContext = SharedComponents.Firebase.context()
  const [isDownloadBusy, setIsDownloadBusy] = useState(false)
  const isCustomDomain = useSelector(state => state.app.isCustomDomain)
  const toast = useToast()

  useEffect(() => {
    if (musicList && song_id) {
      setSong(musicList?.find(x => x.id === song_id))
    }
  }, [musicList, song_id])

  useEffect(() => {
    getDominantColor()
  }, [song])

  useEffect(() => {
    if (user_id && song) {
      checkIsPurchasedByUser()
    }
  }, [user_id, song])

  async function checkIsPurchasedByUser () {
    if (song?.isPurchasedByUser == null) {
      const _isPurchased = await firebaseContext.getDocument({
        path: `profile/${singer_id}/songs/${song_id}/purchases/${user_id}`,
        checkOnlyExistence: true
      })
      console.log('IsPurchased:', _isPurchased)
      setSong(prev => ({ ...prev, isPurchasedByUser: _isPurchased }))
    }
  }

  function getDominantColor () {
    if (song) {
      const ctf = new colorTheif()
      const img = document.createElement('img')
      img.crossOrigin = 'Anonymous'
      img.src = song?.imageUrl
      img.addEventListener('load', ev => {
        const colorRes = ctf.getColor(img)
        setDominantColor({
          light: `rgba(${colorRes.join(',')},0.55)`,
          original: `rgba(${colorRes.join(',')})`
        })
      })
    }
  }

  function goHome () {
    console.log('Will go to:', isCustomDomain, singer_id)
    if (isCustomDomain) {
      navigate('/')
    } else {
      navigate(`/${singer_id}`)
    }
  }

  function triggerPlay () {
    if (!user_id) {
      dispatch(setAppState({ key: 'isAuthModalOpen', value: true }))
      return
    }
    dispatch(setCurrentMusic(song))
  }

  return (
    <Grid
      gridTemplateRows={'50px 1fr'}
      w='full'
      h='full'
      gap='4'
      p='3'
      color='white'
    >
      <SharedComponents.AppComponent.Skeleton_V2 isLoaded={singerProfile}>
        <HStack
          bg='#1F1F1F'
          borderRadius={'lg'}
          px='4'
          py='2'
          spacing={'3'}
          cursor={'pointer'}
          transition={'all 200ms'}
          _hover={{
            bg: 'gray.700'
          }}
          onClick={goHome}
        >
          <FaArrowLeft />
          <SharedComponents.AppComponent.LazyImage_V2
            h='35px'
            w='35px'
            borderRadius={'md'}
            src={singerProfile?.photoUrl}
          />
          <VStack spacing={'-1'} align={'flex-start'}>
            <Text fontSize={'12px'}>See all releases from</Text>
            <Text fontSize={'14px'}>
              {' '}
              {singerProfile?.displayName ||
                `${singerProfile?.firstName || ''} ${
                  singerProfile?.lastName || ''
                }` ||
                ''}
            </Text>
          </VStack>
        </HStack>
      </SharedComponents.AppComponent.Skeleton_V2>
      <GridItem borderRadius={'lg'} h='full' overflow={'scroll'}>
        <VStack
          w='full'
          h='max-content'
          color='white'
          spacing={'5'}
          align={'flex-start'}
        >
          <VStack w='full' h='max-content' pos='relative'>
            <SharedComponents.AppComponent.LazyImage_V2
              h='300px'
              w='100%'
              borderRadius={'lg'}
              src={song?.imageUrl}
            />
            <HStack
              pos='absolute'
              w='full'
              bottom='0px'
              px='4'
              h='full'
              color='white'
              borderBottomRadius={'md'}
              py='4'
              bgGradient={`linear(to-t, #15151E, #15151E50, transparent)`}
              align={'flex-end'}
            >
              <Grid
                gridTemplateColumns={!isMobile && 'auto 1fr'}
                gridTemplateRows={isMobile && 'repeat(2, auto)'}
                columnGap={'5'}
                rowGap={'3'}
                mb={isMobile && '-50px'}
              >
                <GridItem alignSelf={'center'} gridRowStart={isMobile && '2'}>
                  <SharedComponents.AppComponent.Skeleton_V2
                    isLoaded={song && song?.isPurchasedByUser !== null}
                    h='max-content'
                  >
                    <IconButton
                      size='lg'
                      borderRadius={'full'}
                      bg='white'
                      color='black'
                      icon={<FaPlay />}
                      borderWidth={'1px'}
                      borderColor={'white'}
                      _hover={{
                        bg: 'black',
                        color: 'white',
                        borderColor: 'green'
                      }}
                      onClick={triggerPlay}
                      w={'55px'}
                      h={'55px'}
                    />
                  </SharedComponents.AppComponent.Skeleton_V2>
                </GridItem>
                <Text
                  fontSize={isMobile ? '34px' : '44px'}
                  fontWeight={'bold'}
                  textShadow={'-2px 2px 10px #00000060'}
                >
                  {song?.name}
                </Text>
              </Grid>
            </HStack>
          </VStack>
          {isMobile && <Box h='30px' />}
          <HomeComponents.MusicList currentSong={song} />
        </VStack>
      </GridItem>
    </Grid>
  )
}
