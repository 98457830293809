import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Spinner,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import Logo from '../../assets/images/logo-horizontal.png'
import * as FirebaseFunctions from 'firebase/functions'
import SharedComponents from 'shared-components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from '../../redux/slices/appSlice'

export default ({ children }) => {
  const firebaseContext = SharedComponents.Firebase.context()
  const adminAppId = useSelector(state => state.app.adminAppId)
  const adminProfile = useSelector(state => state.app.adminProfile)
  const dispatch = useDispatch()
  const [isMobile] = useMediaQuery('(max-width: 800px')

  useEffect(() => {
    fetchAdminProfile()
  }, [])

  async function fetchAdminProfile () {
    const profile = await firebaseContext.getDocument({
      path: `profile/${adminAppId}`
    })
    console.log('Artist Profile is:', profile)
    dispatch(
      setAppState({
        key: 'adminProfile',
        value: profile
      })
    )
  }

  return !adminProfile ? (
    <VStack w='full' h='full' justify={'center'} align={'center'}>
      <Spinner size='lg' color='white' />
    </VStack>
  ) : (
    <Grid
      w={'full'}
      h='full'
      gridTemplateRows={'repeat(3, 1fr)'}
      gridTemplateColumns={isMobile ? '1' : 'repeat(2, 1fr)'}
    >
      <GridItem
        gridArea={'1 / 1 / 4 / 2'}
        bg='#1b1f1f'
        color='white'
        alignContent={'center'}
        px='10'
      >
        <VStack align={isMobile ? 'center' : 'flex-start'}>
          <HStack justify={'center'}>
            <SharedComponents.AppComponent.LazyImage
              src={adminProfile.photoUrl}
              h='40px'
              w='40px'
              borderRadius={'full'}
              objectFit={'cover'}
            />
            <VStack spacing={'0'} color='white' align={'flex-start'}>
              <Text fontSize={'14px'} fontWeight={'500'}>
                Your favorite,
              </Text>
              <Text fontSize={'16px'} fontWeight={'500'}>
                {adminProfile.name}
              </Text>
            </VStack>
          </HStack>
          <VStack
            spacing={'0'}
            w='full'
            align={isMobile ? 'center' : 'flex-start'}
          >
            <Text
              fontWeight={'600'}
              fontSize={isMobile ? '24px' : '30px'}
              // w={isMobile ? 'auto' }
              fontFamily={'roboto'}
              // lineHeight={'4rem'}
            >
              Stream all new releases of
            </Text>
            <Text
              fontWeight={'800'}
              fontSize={isMobile ? '34px' : '60px'}
              // w='80%'
              fontFamily={'roboto'}
              // lineHeight={'4rem'}
            >
              {adminProfile?.name}
            </Text>
          </VStack>
        </VStack>
        {children}
      </GridItem>
      {!isMobile && (
        <GridItem
          className='imageContainer'
          gridArea={'1 / 2 / 4 / 3'}
          bg='#2E4F4F'
          overflow={'hidden'}
        >
          <VStack
            h='full'
            w='full'
            pos='relative'
            justify={'center'}
            align={'flex-start'}
            color='white'
            px='55px'
          >
            <Box
              pos='absolute'
              top='0'
              bottom={'0'}
              left='0'
              right={'0'}
              filter='blur(5px)'
              bgImage={adminProfile?.coverUrl}
              bgSize={'cover'}
              bgPos={'center'}
              transform={'scale(1.05)'}
              zIndex={'0'}
            />
            <Box
              pos='absolute'
              top='0'
              bottom={'0'}
              left='0'
              right={'0'}
              bgGradient='linear(to-r, #1b1f1f,  transparent)'
              zIndex={'0'}
            />
          </VStack>
        </GridItem>
      )}
    </Grid>
  )
}
