import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import { HomeComponents } from './components'
import SharedComponents from 'shared-components'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const SocialButtons = () => {
  const singerProfile = useSelector(state => state.app.singerProfile)
  const [isMobile] = useMediaQuery('(max-width: 800px)')

  const LOGOS = {
    instagram: 'https://img.icons8.com/3d-fluency/375/instagram-logo.png',
    tiktok: 'https://img.icons8.com/3d-fluency/375/tiktok-logo.png',
    twitter: 'https://img.icons8.com/color/240/twitterx--v1.png'
  }

  function fetchSocials () {
    if (singerProfile?.social) {
      const response = Object.keys(singerProfile.social)
        .filter(key => singerProfile.social[key].length > 0)
        .map(key => {
          if (singerProfile.social[key].length > 0) {
            return {
              type: key,
              userName: singerProfile.social[key],
              logoUrl: LOGOS[key],
              url: `https://www.${key}.com/${
                key === 'twitter'
                  ? `@${singerProfile.social[key]}`
                  : singerProfile.social[key]
              }`
            }
          }
        })
      return response
    }
  }

  function goToSocial ({ url }) {
    window.open(url, '_blank')
  }
  return (
    <>
      {fetchSocials()?.length > 0 && !isMobile && (
        <Text fontSize={'20px'} fontWeight={'semibold'} pt='4'>
          Socials
        </Text>
      )}
      <Stack
        direction={isMobile ? 'column' : 'row'}
        w='full'
        flexWrap={'wrap'}
        spacing={'3'}
        align={'flex-start'}
      >
        {fetchSocials()?.map((social, index) => (
          <Button
            key={`singer_social_${social.type}`}
            size='sm'
            boxShadow={'0px 10px 14px 0px rgba(0,0,0,0.75)'}
            _hover={{
              bg: 'black',
              color: 'white'
            }}
            w={isMobile ? 'full' : 'auto'}
            onClick={() => goToSocial(social)}
            leftIcon={
              <SharedComponents.AppComponent.LazyImage_V2
                borderRadius='md'
                src={social.logoUrl}
                w='20px'
                h='20px'
              />
            }
          >
            Follow on {social.type}
          </Button>
        ))}
      </Stack>
    </>
  )
}

export default () => {
  const singerProfile = useSelector(state => state.app.singerProfile)
  const [isMobile] = useMediaQuery('(max-width: 800px)')

  const AboutSection = () => (
    <VStack
      bg='#1F1F1F'
      borderRadius={'lg'}
      w='full'
      h={isMobile ? 'max-content' : 'full'}
      align={'flex-start'}
      px='6'
      py='3'
    >
      {!isMobile && (
        <Text fontSize={'20px'} fontWeight={'semibold'}>
          About{' '}
          {singerProfile?.displayName ||
            `${singerProfile?.firstName || ''} ${
              singerProfile?.lastName || ''
            }` ||
            ''}
        </Text>
      )}
      <SharedComponents.AppComponent.Skeleton_V2 isLoaded={singerProfile}>
        <Text>{singerProfile?.bio}</Text>
      </SharedComponents.AppComponent.Skeleton_V2>
      <SocialButtons />
    </VStack>
  )

  return (
    <Grid
      gridTemplateColumns={isMobile ? '1fr' : '0.7fr 0.3fr'}
      w='full'
      h='full'
      gap='3'
      p='3'
      color='white'
    >
      <GridItem borderRadius={'lg'} h='full' overflow={'scroll'}>
        <VStack w='full' h='max-content'>
          <VStack w='full' h='max-content' pos='relative'>
            <SharedComponents.AppComponent.LazyImage_V2
              h={isMobile ? '150px' : '220px'}
              w='100%'
              borderRadius={'lg'}
              src={singerProfile?.coverUrl}
            />
            <HStack
              pos='absolute'
              w='full'
              bottom={isMobile ? '-50px' : '-70px'}
              px={isMobile ? '3' : '6'}
              color='white'
            >
              <SharedComponents.AppComponent.LazyImage_V2
                h={isMobile ? '80px' : '120px'}
                w={isMobile ? '80px' : '120px'}
                borderRadius={'full'}
                border='4px solid #15151E'
                src={singerProfile?.photoUrl}
              />
              <Grid gridTemplateRows={'repeat(2, 1fr)'}>
                <GridItem />
                <VStack mt='-10px'>
                  <Text
                    fontWeight={'black'}
                    fontSize={isMobile ? '24px' : '40px'}
                  >
                    {singerProfile?.displayName ||
                      `${singerProfile?.firstName || ''} ${
                        singerProfile?.lastName || ''
                      }` ||
                      ''}
                  </Text>
                </VStack>
              </Grid>
            </HStack>
          </VStack>
          <Box h={isMobile ? '60px' : '70px'} />
          {isMobile && <AboutSection />}
          <VStack
            w='full'
            align={'flex-start'}
            px={isMobile ? '0' : '5'}
            mt={isMobile && '3'}
          >
            <HomeComponents.MusicList />
          </VStack>
        </VStack>
      </GridItem>
      {!isMobile && <AboutSection />}
    </Grid>
  )
}
