import './App.css'
import AppRoutes, { extractNestedRoutes } from './routes'
import {
  createBrowserRouter,
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom'
import { VStack } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { setUserProps } from './redux/slices/userSlice'
import { useEffect, useState } from 'react'
import { AppLayout } from './screens/mainApp/appLayout'
import axios from 'axios'
import SharedComponents from 'shared-components'
import { setAppState } from './redux/slices/appSlice'

function MainAppRoutes ({isDemo}) {
  console.log('Rendering for demo?', isDemo)
  const _routes = extractNestedRoutes(AppRoutes.MainApp)
  return createBrowserRouter(_routes)
}

function App () {
  const dispatch = useDispatch()
  const firebaseContext = SharedComponents.Firebase.context()

  useEffect(() => {
    getIpInfo()
  }, [])

  async function getIpInfo () {
    try {
      const token = process.env.REACT_APP_IPINFO_TOKEN
      const response = await axios.get(`https://ipinfo.io/json?token=${token}`)
      if (response.data) {
        dispatch(setUserProps({ key: 'ipinfo', value: response.data }))
      }
    } catch (ex) {
      console.warn("Could not get user's IP Info")
    }
  }

  const RenderScreens = () => {
    if (
      !process.env.NODE_ENV ||
      process.env.NODE_ENV === 'development' ||
      window.location.host.includes('streamconnect')
    ) {
      return (
        <Router>
          <Routes>
            {/* <Route path='*' element={<NotFound />} /> */}
            <Route path=':singer_id' element={<AppLayout />}>
              {MainAppRoutes({isDemo: true}).routes.map(_route => (
                <Route key={`routeKey_${_route.path}`} {..._route} />
              ))}
            </Route>
          </Routes>
        </Router>
      )
    }
    dispatch(setAppState({ key: 'isCustomDomain', value: true }))
    return (
      <Router>
        <Routes>
          <Route path='/' element={<AppLayout />}>
            {MainAppRoutes().routes.map(_route => (
              <Route key={`routeKey_${_route.path}`} {..._route} />
            ))}
          </Route>
        </Routes>
      </Router>
    )
  }

  return (
    <VStack h='full' w='full' bg=''>
      <RenderScreens />
    </VStack>
  )
}

const NotFound = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [])
  return <></>
}

export default App
