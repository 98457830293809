import { configureStore, createReducer } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import appReducer from './slices/appSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import musicSlice from './slices/musicSlice'
import subscriptionSlice from './slices/subscriptionSlice'
import paymentMethodSlice from './slices/paymentMethodSlice'

const userPersistedReducer = persistReducer(
  { key: 'user', storage },
  userReducer
)
const appPersistedReducer = persistReducer({ key: 'app', storage }, appReducer)

export const store = configureStore({
  reducer: {
    user: userPersistedReducer,
    app: appPersistedReducer,
    music: musicSlice,
    subscription: subscriptionSlice,
    paymentMethods: paymentMethodSlice
  }
})

export const persistor = persistStore(store)
