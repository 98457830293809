import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useMediaQuery,
  useToast,
  VStack
} from '@chakra-ui/react'
import { FaPause, FaPlay, FaX } from 'react-icons/fa6'
import './components.css'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import SharedComponents, {
  FirestorePackageExport as FirestorePackage
} from 'shared-components'
import { Howl } from 'howler'
import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentMusic,
  setMusic,
  setSoundObject
} from '../../../redux/slices/musicSlice'
import * as FirebaseFunctions from 'firebase/functions'
import colorTheif from 'colorthief'
import { RiCollapseDiagonal2Line } from 'react-icons/ri'
import moment from 'moment'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { setUser, setUserProps } from '../../../redux/slices/userSlice'
import { v4 as uuidv4 } from 'uuid'
import { Formik } from 'formik'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile
} from 'firebase/auth'
import Hls from 'hls.js'
import { MdClose } from 'react-icons/md'
import { FiChevronUp } from 'react-icons/fi'
import { setAppState } from '../../../redux/slices/appSlice'
import { LuDownload } from 'react-icons/lu'

const SimpleAudioPlayer = () => {
  // const musicState = useSelector(state => state.music)
  const userState = useSelector(state => state.user)
  const subscriptionState = useSelector(state => state.subscription)
  const dispatch = useDispatch()
  const priceModalRef = useRef()
  const [availableSubscriptions, setAvailableSubscriptions] = useState()
  const firebaseContext = SharedComponents.Firebase.context()
  const [allowPlay, setAllowPlay] = useState(false)
  const [isCheckoutBusy, setIsCheckoutBusy] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const currentSound = useSelector(state => state.music.soundObject)
  const currentMusic = useSelector(state => state.music.current)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [showPaymentConfirmationModal, setShowPaymentConfirmationModal] =
    useState(false)
  const toast = useToast()
  const [sessionId, setSessionId] = useState()
  const authModal = useRef()
  const [isMobile] = useMediaQuery('(max-width: 800px)')
  const singerProfile = useSelector(state => state.app.singerProfile)
  const audioElem = useRef()
  const [isDownloadBusy, setIsDownloadBusy] = useState(false)

  // After a successful transaction, we are confirming the purchase of the music,
  // via the URL params

  useEffect(() => {
    if (currentMusic) {
      performOperations()
    }
  }, [currentMusic])

  async function performOperations () {
    setAllowPlay(false)
    const _plan = await checkMusicPlan(currentMusic)
    if (_plan.mode === 'play') {
      setAllowPlay(true)
      performPlay()
    } else {
      setAllowPlay(false)
      stopMusic({ userNotPaid: true })
    }
  }

  useEffect(() => {
    if (allowPlay && currentMusic) {
      setNameAnimation()
    }
  }, [allowPlay, currentMusic, expanded])

  async function checkMusicPlan (musicData) {
    if (
      (!musicData.priceId || musicData.priceId.length <= 0) &&
      !musicData.useAlbumPricingPlan
    ) {
      // The music has been set as "Free to stream" by the artist
      // This means the music also doest derive it's price from the associated album
      return {
        mode: 'play'
      }
    } else if (musicData.isPurchasedByUser) {
      return {
        mode: 'play'
      }
    } else {
      // Now first we get the PriceID of the music
      // Here, if `useAlbumPricingPlan` is true,
      // then we get the PriceID of the album
      // If false, we simply return the PriceId of the music

      // Now here we either use the Album's price ID(if exists), or return the PriceId of the single
      // item
      const _MusicPriceId = () => {
        if (
          musicData.album &&
          musicData.useAlbumPricingPlan &&
          musicData.album.priceId &&
          musicData.album.priceId.length > 0
        ) {
          // console.log('Using album monetization plan...')
          return musicData.album.priceId
        } else {
          // console.log('Using music monetization plan...')
          return musicData.priceId
        }
      }

      const _activeSubs = userState.subscriptions?.filter(
        x => x.status === 'active' && !x.ended_at
      )
      console.log('Active subs:', _activeSubs)

      if (_MusicPriceId()?.some(x => x.type === 'recurring')) {
        // Now we know that the music is part of a subscription
        console.log(
          'Music is part of a subscription. Printing music price IDs',
          _MusicPriceId()
        )

        const isUserSubscribed = _activeSubs?.some(x =>
          _MusicPriceId()?.some(({ id }) => x.plan.id === id)
        )
        if (isUserSubscribed)
          return {
            mode: 'play'
          }
      }

      // The user has not paid for the music
      // So we trigger the purchase modal
      const music_subs_orPrices = subscriptionState
        ?.filter(
          x => x.active && _MusicPriceId()?.some(({ id }) => x.id === id)
        )
        .map((item, index) => ({ ...item }))
      setAvailableSubscriptions(music_subs_orPrices)
      priceModalRef.current?.triggerModal()

      return {
        mode: 'unpaid'
      }
    }
  }

  function setNameAnimation () {
    const scrollContainer = document.getElementById('scroll-container')
    const scrollText = document.getElementById('scroll-text')
    if (scrollContainer && scrollText) {
      if (!expanded) {
        console.log('Setting animation again....')
        scrollContainer.classList.remove('expanded-text')
        const containerWidth = scrollContainer.offsetWidth
        const textWidth = scrollText.scrollWidth
        console.log(
          'Text width:',
          textWidth,
          ' and containerWidth:',
          containerWidth
        )
        if (textWidth < containerWidth) {
          scrollText.style.setProperty('animation', 'none')
        } else {
          scrollText.style.setProperty(
            'animation',
            'scroll 8s ease-in-out infinite'
          )
          scrollText.style.setProperty('--text-width', `${textWidth}px`)
        }
      } else {
        console.log('Removing animation...')
        scrollContainer.classList.add('expanded-text')
      }
    }
  }

  async function performPlay () {
    setSessionId(uuidv4())
    const func_signedUrl = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'getSignedMediaUrl'
    )
    console.log(
      `Fetching music of: ${singerProfile.id} for music: ${currentMusic.id}`
    )
    const result = await func_signedUrl({
      singerId: singerProfile.id,
      songId: currentMusic.id
    })
    const hls_file = new Blob([result.data], { type: 'application/x-mpegURL' })
    const hls_url = URL.createObjectURL(hls_file)
    // if (musicState.soundObject?.howler) {
    //   musicState.soundObject.howler?.stop()
    //   dispatch(setSoundObject(null))
    // }

    if (Hls.isSupported()) {
      console.log('HLS supported!')
    }
    const audio = document.createElement('audio')
    audio.crossOrigin = 'anonymous'
    const hls = new Hls()
    hls.loadSource(hls_url)
    hls.attachMedia(audio)
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      console.log('Manifest parsed!')
      // audio.play()
      dispatch(
        setSoundObject({
          isPlaying: true,
          isLoaded: true
        })
      )

      audio.addEventListener('ended', () => {
        dispatch(
          setSoundObject({
            isPlaying: false
          })
        )
      })

      audioElem.current = audio
      audio.play()
      recordStreamOverview()
    })

    // const sound = new Howl({
    //   src: hls_url,
    //   format: '',
    //   onload: () =>
    //     dispatch(
    //       setSoundObject({
    //         isPlaying: true,
    //         isLoaded: true
    //       })
    //     ),
    //   onend: () =>
    //     dispatch(
    //       setSoundObject({
    //         isPlaying: false
    //       })
    //     )
    // })
    // const soundId = sound.play()
    // dispatch(
    //   setSoundObject({
    //     howler: sound,
    //     id: soundId
    //   })
    // )
  }

  function playPause () {
    // setSoundObj(prev => ({ ...prev, isPlaying: !prev.isPlaying }))
    const seek = audioElem.current?.currentTime
    recordStream(seek)
    dispatch(
      setSoundObject({
        isPlaying: !currentSound.isPlaying
      })
    )
    if (currentSound.isPlaying) audioElem.current?.pause()
    else {
      audioElem.current?.play()
    }
  }

  async function recordStreamOverview () {
    await firebaseContext.addDocument({
      path: `profile/${singerProfile.id}/songs/${currentMusic.id}/streams-overview-location/${userState.ipinfo.country}-${userState.ipinfo.city}`,
      data: {
        city: userState.ipinfo.city,
        country: userState.ipinfo.country,
        streamCount: FirestorePackage.increment(1)
      },
      ignoreStateUpdate: true,
      allowMerge: true
    })
    await firebaseContext.addDocument({
      path: `profile/${singerProfile.id}/songs/${currentMusic.id}/streams-overview-listeners/${userState.id}`,
      data: {
        streamCount: FirestorePackage.increment(1),
        name: userState.name,
        id: userState.id
      },
      ignoreStateUpdate: true,
      allowMerge: true
    })
  }

  async function recordStream (duration) {
    try {
      // console.log('Recoridng stream. Customer ID:', userState)
      await firebaseContext.addDocument({
        path: `profile/${singerProfile.id}/songs/${currentMusic.id}/streams/${sessionId}`,
        data: {
          userId: userState.id,
          userName: userState.name,
          customerId: userState.customerId,
          duration: duration,
          date: Date.now(),
          ipinfo: userState.ipinfo
        },
        ignoreStateUpdate: true
      })
    } catch (ex) {
      console.log('Could not add stream record:', ex)
    }
  }

  function setSelectedSubscription (id) {
    setAvailableSubscriptions(prev => {
      const _shallow = [...prev]
      const updated = _shallow.map(item => {
        if (item.id === id) {
          return {
            ...item,
            isSelected: item.isSelected ? !item.isSelected : true
          }
        } else {
          return { ...item, isSelected: false }
        }
      })
      // const _specificIdx = _shallow.findIndex(x => x.id === id)
      // _shallow[_specificIdx] = {
      //   ..._shallow[_specificIdx],
      //   isSelected: _shallow[_specificIdx].isSelected
      //     ? !_shallow[_specificIdx].isSelected
      //     : true
      // }
      return updated
    })
  }

  async function startDownload () {
    setIsDownloadBusy(true)
    const link = document.createElement('a')

    try {
      const func_signedDownloadUrl = FirebaseFunctions.httpsCallable(
        firebaseContext.functions,
        'getSignedDownloadUrl'
      )
      const _downloadUrl = await func_signedDownloadUrl({
        singerId: singerProfile.id,
        songId: currentMusic.id,
        fileName: currentMusic.fileName
      })

      console.log('Download URL:', _downloadUrl)

      link.href = _downloadUrl.data
      link.setAttribute('download', '')
      document.body.appendChild(link)
      link.click()
      // document.removeChild(link)
    } catch (ex) {
      console.log('Could not generate download link!', ex)
      toast({
        title: 'Something went wrong',
        description: 'We are fixing it shortly.',
        status: 'error',
        isClosable: true,
        duration: 1500
      })
    }
    setIsDownloadBusy(false)
  }

  async function generatePaymentLink () {
    setIsCheckoutBusy(true)
    const priceObj = availableSubscriptions.find(x => x.isSelected)
    const checkout = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'createCheckout'
    )
    try {
      const data = {
        priceId: priceObj.id,
        customerId: userState.customerId,
        mode: priceObj.recurring?.interval ? 'subscription' : 'payment',
        redirectUrl: `${
          window.location.href
        }?sessionId={CHECKOUT_SESSION_ID}&songId=${
          currentMusic.id
        }&paymentMode=${
          priceObj.recurring?.interval ? 'subscription' : 'payment'
        }`,
        songId: currentMusic.id,
        stripeAccountId: singerProfile.stripeAccountId
      }
      console.log('Generating checkout session with data:', data)
      const result = await checkout(data)
      if (result.data.url) {
        console.log('Checkout session created:', result.data.id)
        window.open(result.data.url, '_self')
      } else {
        console.log('Could not generate a checkout session.', result)
      }

      // dispatch(setAllSubscriptions(result.data))
      // dispatch(setAllMusic(allSongs))
    } catch (ex) {
      console.warn('CloudFunction error:', ex)
    }
    setIsCheckoutBusy(false)
  }
  useEffect(() => {
    const sessionId = searchParams.get('sessionId')
    const songId = searchParams.get('songId')
    const paymentMode = searchParams.get('paymentMode')

    if (sessionId && songId) finalizePurchase(sessionId, songId, paymentMode)
  }, [])

  async function finalizePurchase (sessionId, songId, paymentMode) {
    setShowPaymentConfirmationModal(true)

    if (paymentMode === 'subscription') {
      const getCustomer = FirebaseFunctions.httpsCallable(
        firebaseContext.functions,
        'retrieveCustomer'
      )

      try {
        const result = await getCustomer({
          id: userState.customerId,
          stripeAccountId: singerProfile.stripeAccountId
        })
        console.log('Customer result is:', result.data)
        if (result.data.subscriptions) {
          toast({
            title: 'Your subscription is active!',
            description: 'You can now stream the music/album',
            status: 'success',
            isClosable: true
          })
          dispatch(
            setUserProps({
              key: 'subscriptions',
              value: result.data.subscriptions
            })
          )
          navigate(location.pathname)
        } else {
          toast({
            title: 'Something went wrong!',
            description: "We couldn't process your payment at the moment.",
            status: 'error',
            isClosable: true
          })
        }
      } catch (ex) {
        toast({
          title: 'Something went wrong!',
          description: "We couldn't process your payment at the moment.",
          status: 'success',
          isClosable: true
        })
        console.log('CloudFunction error:', ex)
      }
    } else if (paymentMode === 'payment') {
      const confirmMusicPurchase = FirebaseFunctions.httpsCallable(
        firebaseContext.functions,
        'confirmMusicPurchase'
      )
      try {
        const result = await confirmMusicPurchase({
          sessionId,
          songId,
          userId: userState.id,
          singerId: singerProfile.id,
          stripeAccountId: singerProfile.stripeAccountId
        })
        console.log('Finale result is:', result.data)
        if (result.data.success) {
          dispatch(setMusic({ id: songId, isPurchasedByUser: true }))
          toast({
            title: "You're all set!",
            description: 'You can now stream the music/album',
            status: 'success',
            isClosable: true
          })
          navigate(location.pathname)
        }
      } catch (ex) {
        toast({
          title: 'Something went wrong!',
          description: "We couldn't process your payment at the moment.",
          status: 'success',
          isClosable: true
        })
        console.warn('CloudFunction error:', ex)
      }
    }
    setShowPaymentConfirmationModal(false)
  }

  function stopMusic ({ userNotPaid }) {
    const seek = audioElem.current?.currentTime
    !userNotPaid && recordStream(seek)
    setAllowPlay(false)
    audioElem.current?.pause()
    if (!userNotPaid) dispatch(setCurrentMusic(null))
    dispatch(setSoundObject(null))
  }

  return (
    <AnimatePresence>
      {/* The collapsed player */}
      {allowPlay && !expanded && (
        <motion.div
          key='music_player_collapsed'
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            zIndex: '999',
            paddingLeft: '5px',
            paddingRight: '5px'
          }}
          initial={{ opacity: 0, bottom: '-50px' }}
          animate={{ opacity: 1, bottom: isMobile ? '2px' : '5px' }}
          exit={{ opacity: 0, bottom: '-50px' }}
        >
          <HStack
            className='music-player-container'
            transition={'all 300ms'}
            w='full'
            boxShadow={'0px 5px 10px 0px rgba(19,51,133,0.4)'}
            borderRadius={'sm'}
            // h={'45px'}
            alignItems={'center'}
            pos='relative'
            overflow={'hidden'}
            px={'1'}
            py={'1'}
            bg={'#323247'}
            color='white'
            spacing={'4'}
          >
            <HStack
              role='group'
              borderRadius={'full'}
              spacing={'3'}
              zIndex={'2'}
              px='0'
              py='0'
              w='max-content'
            >
              <SharedComponents.AppComponent.LazyImage_V2
                h={'45px'}
                w={'45px'}
                src={currentMusic?.imageUrl}
                borderRadius={'sm'}
              />

              <IconButton
                icon={
                  currentSound?.isPlaying ? (
                    <FaPause size='20' />
                  ) : (
                    <FaPlay size='20' />
                  )
                }
                h='45px'
                w='45px'
                p='0'
                size='sm'
                bg={'#323247'}
                color={'white'}
                _hover={{
                  bg: 'white',
                  color: 'black'
                }}
                isLoading={!currentSound?.isLoaded}
                onClick={playPause}
              />
              <Text
                w='max-content'
                fontSize={'14px'}
                fontWeight={'bold'}
                style={{
                  textWrap: 'nowrap'
                }}
              >
                {currentMusic?.name}
              </Text>
            </HStack>
            <MusicProgressBar
              audioElem={audioElem}
              style={{
                w: 'full',
                direction: 'row',
                spacing: '4',
                pt: '0',
                justifyContent: isMobile && 'flex-start'
              }}
              hideSlider={isMobile}
            />

            {/* <IconButton
              size='md'
              h='45px'
              w='45px'
              icon={<FiChevronUp size='20px' />}
              borderRadius={'md'}
              bg={'#323247'}
              color={'white'}
              _hover={{
                bg: 'white',
                color: 'black'
              }}
            /> */}
            <HStack spacing={'0'}>
              <IconButton
                size='md'
                h='45px'
                w='45px'
                icon={<LuDownload size='20px' />}
                borderRadius={'md'}
                bg={'#323247'}
                color={'white'}
                _hover={{
                  bg: 'white',
                  color: 'black'
                }}
                isLoading={isDownloadBusy}
                onClick={startDownload}
              />
              <IconButton
                size='md'
                h='45px'
                w='45px'
                icon={<MdClose size='20px' />}
                borderRadius={'md'}
                bg={'#323247'}
                color={'white'}
                _hover={{
                  bg: 'white',
                  color: 'black'
                }}
                onClick={stopMusic}
              />
            </HStack>
          </HStack>
        </motion.div>
      )}
      <Modal
        key={'payment_confirm_modal'}
        bg='#1B1F1F'
        isOpen={showPaymentConfirmationModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody bg='#1B1F1F' border='1px solid green' py='4'>
            <VStack
              justify={'center'}
              align={'center'}
              w='full'
              h='max-content'
              color='white'
            >
              <Text fontSize={'18px'} fontWeight={'semibold'}>
                Confirming your purchase, please wait...
              </Text>
              <Spinner size={'lg'} color='white' />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <SharedComponents.AppComponent.Modal
        key={'price_modal_home'}
        ref={priceModalRef}
        // onProceed={generatePaymentLink}
        size='md'
        proceedBtnLabel='Proceed To Pay'
        isUserApp={true}
        isProceedDisabled={
          availableSubscriptions?.every(x => x.isSelected !== true) ||
          !singerProfile?.isProActive
        }
        onProceed={generatePaymentLink}
        isProceedBusy={isCheckoutBusy}
        onClose={() => dispatch(setCurrentMusic(null))}
      >
        <Text
          fontSize={'26px'}
          fontWeight={'bold'}
          fontFamily={'roboto'}
          lineHeight={'2rem'}
        >
          Listen to "{currentMusic?.name}"
        </Text>
        <Text fontSize={'16px'} fontWeight={'semibold'}>
          Select a plan
        </Text>
        {availableSubscriptions?.map(sub_single => (
          <Grid
            key={`player_payment_modal_planItem_${sub_single.id}`}
            cursor={'pointer'}
            transition={'all 300ms'}
            gridTemplateRows={'repeat(2, 1fr)'}
            gridTemplateColumns={'1fr 130px'}
            w='full'
            bg={sub_single.isSelected ? 'green' : 'black'}
            border='1px solid black'
            // borderLeftWidth={sub_single.isSelected ? '5px' : '1px'}
            borderRadius={'md'}
            px='3'
            py='2'
            _hover={{
              border: '1px solid green',
              transform: 'scale(1.03)'
            }}
            onClick={() => setSelectedSubscription(sub_single.id)}
          >
            <GridItem gridArea={'1 / 1 / 2 / 2'}>
              <HStack>
                {/* {sub_single.isSelected && <FaCheckCircle color='green' />} */}
                <Text fontSize={'16px'} fontWeight={'bold'}>
                  {sub_single.product.name}
                </Text>
              </HStack>
            </GridItem>
            <GridItem gridArea={'2 / 1 / 3 / 2'}>
              <Text textTransform={'capitalize'}>
                {sub_single.recurring?.interval
                  ? `${sub_single.recurring?.interval}ly Subscription`
                  : 'One-Time Fee'}
              </Text>
            </GridItem>
            <GridItem
              gridArea={'1 / 2 / 3 / 3'}
              alignContent={'center'}
              textAlign={'right'}
            >
              ${sub_single.unit_amount / 100}
              {sub_single.recurring?.interval
                ? `/${sub_single.recurring?.interval}`
                : ''}
            </GridItem>
          </Grid>
        ))}
        {!singerProfile?.isProActive && (
          <Text fontSize={'14px'} p='2' bg='#322B3C' fontWeight={'semibold'}>
            The artist cannot yet collect payments. Please contact the artist.
          </Text>
        )}
      </SharedComponents.AppComponent.Modal>
    </AnimatePresence>
  )
}

const MusicProgressBar = ({
  musicImageUrl,
  audioElem,
  style = {},
  hideSlider
}) => {
  const currentSound = useSelector(state => state.music.soundObject)
  // const currentHowler = useSelector(state => state.music.soundObject?.howler)
  const currentMusic = useSelector(state => state.music.current)
  const userState = useSelector(state => state.user)
  const [progress, setProgress] = useState(0)
  const [dominantColor, setDominantColor] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const firebaseContext = SharedComponents.Firebase.context()

  // useEffect(() => {
  //   const duration = audioElem.current?.duration
  //   if (currentSound?.isPlaying && duration > 0) {
  //     const progressInterval = setInterval(() => {
  //       if (!isDragging) {
  //         const seek = audioElem.current?.currentTime
  //         setProgress(seek)
  //       }
  //     }, 500)

  //     return () => clearInterval(progressInterval)
  //   }
  // }, [
  //   progress,
  //   currentSound?.isPlaying,
  //   isDragging
  // ])

  // useEffect(() => {
  //   audioElem.current?.addEventListener('timeupdate', () => {
  //     if (!isDragging) {
  //       setProgress(audioElem.current?.currentTime)
  //     }
  //   })
  // }, [currentSound?.isPlaying])

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (!isDragging) {
        setProgress(audioElem.current?.currentTime || 0)
      }
    }

    const audio = audioElem.current

    if (audio && currentSound?.isPlaying) {
      audio.addEventListener('timeupdate', handleTimeUpdate)
    }

    return () => {
      audio?.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [currentSound?.isPlaying, isDragging])

  useEffect(() => {
    getDominantColor()
  }, [currentMusic])

  function getDominantColor () {
    if (currentMusic) {
      const ctf = new colorTheif()
      const img = document.createElement('img')
      img.crossOrigin = 'Anonymous'
      img.src = currentMusic?.imageUrl
      img.addEventListener('load', ev => {
        const colorRes = ctf.getColor(img)
        setDominantColor(`rgba(${colorRes.join(',')},0.55)`)
      })
    }
  }

  function durationSeekText () {
    const _duration = audioElem.current?.duration
    if (style?.direction === 'row') {
      return moment.utc(progress * 1000).format('mm:ss')
    }
    return `${moment.utc(progress * 1000).format('mm:ss')} / ${moment
      .utc(_duration * 1000)
      .format('mm:ss')}`
  }

  return (
    <Stack
      spacing={'0.5'}
      align={'stretch'}
      justify={'center'}
      pt='2'
      direction={'column'}
      {...style}
    >
      {!hideSlider && (
        <Slider
          colorScheme='pink'
          defaultValue={0}
          min={0}
          max={audioElem.current?.duration}
          value={progress}
          onChange={val => {
            setIsDragging(true) // User is dragging
            setProgress(val) // Update progress immediately while dragging
          }}
          onChangeEnd={val => {
            setIsDragging(false) // User stopped dragging
            audioElem.current.currentTime = val // Seek to new position in the track
          }}
        >
          <SliderTrack>
            <SliderFilledTrack bg={dominantColor || 'white'} />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      )}
      <Text color='white' fontSize={'12px'} w='max-content'>
        {durationSeekText()}
      </Text>
    </Stack>
  )
}

export const MusicComponent = {
  SimpleAudioPlayer
}
