import { useEffect, useImperativeHandle, useState } from 'react'
import Routes, { extractNestedRoutes } from '../../routes'
import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import Logo from '../../assets/images/logo-horizontal.png'
import { useNavigate, Outlet, useLocation, useParams } from 'react-router-dom'
import SharedComponents, { FirestorePackageExport } from 'shared-components'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../redux/slices/userSlice'
import { MusicComponent } from './mymusic/components'
import {
  setAllMusic,
  setCurrentMusic,
  setSoundObject
} from '../../redux/slices/musicSlice'
import * as FirebaseFunctions from 'firebase/functions'
import { setAllSubscriptions } from '../../redux/slices/subscriptionSlice'
import { setAppState } from '../../redux/slices/appSlice'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile
} from 'firebase/auth'
import { Formik } from 'formik'

export function AppLayout () {
  const { navbarExpanded, isCustomDomain } = useSelector(state => state.app)
  const appState_singer_id = useSelector(state => state.app.singer_id)
  const dispatch = useDispatch()
  const singer_id = useParams()?.singer_id || appState_singer_id
  const user = useSelector(state => state.user)
  const musicState = useSelector(state => state.music)
  const musicList = useSelector(state => state.music.list)
  const firebaseContext = SharedComponents.Firebase.context()

  async function fetchSingerID () {
    const docRes = await firebaseContext.getDocument({
      path: `domain-map/${window.location.host}`
    })
    if (docRes) {
      dispatch(setAppState({ key: 'singer_id', value: docRes.userName }))
    }
  }

  useEffect(() => {
    if (isCustomDomain) {
      fetchSingerID()
    }
  }, [])

  useEffect(() => {
    if (singer_id) {
      fetch()
    }
  }, [singer_id])

  async function fetch () {
    console.log('SingerID:', singer_id)
    const docRes = await firebaseContext.getDocument({
      path: `profile/${singer_id}`
    })
    // dispatch(
    //   setAppState({
    //     key: 'singerProfile',
    //     value: docRes
    //   })
    // )
    fetchSongs(docRes)
    fetchArtistSubscription(docRes)
  }

  async function fetchSongs (profileData) {
    if (musicList?.length <= 0) {
      const _songList = await firebaseContext.getDocument({
        path: `profile/${singer_id}/songs`,
        _query: FirestorePackageExport.where('status', '==', 'ready')
      })
      const _streamCounts = _songList?.map(async item => {
        return {
          ...item,
          streamCount: await firebaseContext.getDocument({
            path: `profile/${singer_id}/songs/${item.id}/streams`,
            getOnlyLength: true
          })
        }
      })
      const songList = await Promise.all(_streamCounts)
      const getSubscriptions = FirebaseFunctions.httpsCallable(
        firebaseContext.functions,
        'getSubscriptions'
      )
      try {
        const result = await getSubscriptions({
          stripeAccountId: profileData.stripeAccountId
        })
        dispatch(setAllSubscriptions(result.data))
        dispatch(setAllMusic(songList))
      } catch (ex) {
        console.warn('CloudFunction error:', ex)
      }
    }
  }

  const getPriceId = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return 'price_1QX67FJhOwKA5GPTgTONPGSg'
    } else {
      return 'price_1QX67PJhOwKA5GPTnOylm2q4'
    }
  }

  const getProductId = (forceTest) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || forceTest) {
      return 'prod_RPvz96AHIZkGdc'
    } else {
      return 'prod_RPvz66i41P6qPK'
    }
  }

  async function fetchArtistSubscription (artistProfile) {
    const TEST_CONNECTED_ACCOUNTS =
      process.env.REACT_APP_STRIPE_TEST_CONNECTED_ACCOUNTS?.split(',')

    const _forceTest = TEST_CONNECTED_ACCOUNTS?.includes(
      artistProfile.stripeAccountId
    )

    const func_customerProfile = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'getArtistCustomer'
    )

    try {
      const _customer = await func_customerProfile({
        customerId: artistProfile.stripeCustomerId,
        stripeAccountId: artistProfile.stripeAccountId
      })
      const subscription = _customer.data?.subscriptions?.find(
        x => x.plan.product === getProductId(_forceTest)
      )

      console.log('Subscriptions:', subscription)
      const isProActive = subscription?.status === 'active'
      console.log('Subscription Active?', subscription?.status === 'active')

      dispatch(
        setAppState({
          key: 'singerProfile',
          value: {
            ...artistProfile,
            isProActive
          }
        })
      )
      // dispatch(
      //   setAppState({
      //     key: 'isArtistProActive',
      //     value: isArtistProActive
      //   })
      // )
    } catch (ex) {
      console.log('Could not fetch customer profile of artist!', ex)
    }
    // setApiState(prev => ({
    //   ...prev,
    //   isFetching: false,
    //   isFetchComplete: true
    // }))
  }

  return (
    <Box w='full' h='full'>
      <Outlet />
      <MusicComponent.SimpleAudioPlayer />
      <AuthPopup
        key='auth_modal'
        onClose={() => dispatch(setCurrentMusic(null))}
      />
    </Box>
  )
}

const AuthPopup = () => {
  const firebaseContext = SharedComponents.Firebase.context()
  const singerProfile = useSelector(state => state.app.singerProfile)
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.app.isAuthModalOpen)

  function closeModal () {
    dispatch(setAppState({ key: 'isAuthModalOpen', value: false }))
  }

  async function triggerLogin ({ email, password }) {
    try {
      const loginResult = await signInWithEmailAndPassword(
        firebaseContext.auth,
        email,
        password
      )
      const { user } = loginResult
      console.log('User logged in successfully.', user)

      const func_stripeCustomer = FirebaseFunctions.httpsCallable(
        firebaseContext.functions,
        'findCustomer'
      )
      const _customer = await func_stripeCustomer({
        email,
        name: user.displayName,
        uid: user.uid,
        stripeAccountId: singerProfile.stripeAccountId
      })

      // console.log('Customer result is:', _customer.data)
      dispatch(
        setUser({
          id: user.uid,
          customerId: _customer.data.id,
          name: user.displayName
        })
      )
      closeModal()
    } catch (ex) {
      console.warn('Failed to log in with credentials. Try again.', ex)
    }
  }

  async function triggerSignup ({ email, password, displayName }) {
    try {
      const signUpResult = await createUserWithEmailAndPassword(
        firebaseContext.auth,
        email,
        password
      )
      const { user } = signUpResult
      await updateProfile(user, { displayName })
      console.log('User created successfully...', user)
      const func_stripeCustomer = FirebaseFunctions.httpsCallable(
        firebaseContext.functions,
        'createCustomer'
      )
      const _customer = await func_stripeCustomer({
        email,
        name: user.displayName,
        uid: user.uid,
        stripeAccountId: singerProfile.stripeAccountId
      })

      console.log('Customer result is:', _customer.data)
      dispatch(
        setUser({
          id: user.uid,
          customerId: _customer.data.id,
          name: user.displayName
        })
      )
      closeModal()
    } catch (ex) {
      console.warn('Failed to create user with credentials.', ex)
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={closeModal} size='sm' isCentered>
      <ModalOverlay />
      <ModalContent bg='#242128' color='white'>
        <ModalHeader>Login to continue</ModalHeader>
        <ModalCloseButton mt='2' />
        <ModalBody bg='#242128' pb='4'>
          <Tabs position='relative' variant='unstyled'>
            <TabList w='full'>
              <Tab w='full'>Login</Tab>
              <Tab w='full'>Create Account</Tab>
            </TabList>
            <TabIndicator
              mt='-1.5px'
              height='2px'
              bg='blue.500'
              borderRadius='1px'
            />
            <TabPanels>
              <TabPanel px='0'>
                <Formik
                  initialValues={{
                    email: '',
                    password: ''
                  }}
                  onSubmit={async values => {
                    if (
                      !SharedComponents.FunctionExtensions.hasEmptyValue(values)
                    )
                      await triggerLogin(values)
                  }}
                >
                  {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                    <VStack w='full'>
                      <SharedComponents.AppComponent.LabeledInput
                        label='Email'
                        onChange={val => setFieldValue('email', val)}
                        value={values.email}
                      />
                      <SharedComponents.AppComponent.LabeledInput
                        label='Password'
                        onChange={val => setFieldValue('password', val)}
                        value={values.password}
                        inputProps={{
                          type: 'password'
                        }}
                      />
                      <Button
                        size='sm'
                        w='full'
                        mt='2'
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Login
                      </Button>
                    </VStack>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel px='0'>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    displayName: ''
                  }}
                  onSubmit={async values => {
                    if (
                      !SharedComponents.FunctionExtensions.hasEmptyValue(values)
                    )
                      await triggerSignup(values)
                  }}
                >
                  {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                    <VStack w='full'>
                      <SharedComponents.AppComponent.LabeledInput
                        label='Your Name'
                        onChange={val => setFieldValue('displayName', val)}
                        value={values.displayName}
                      />
                      <SharedComponents.AppComponent.LabeledInput
                        label='Email'
                        onChange={val => setFieldValue('email', val)}
                        value={values.email}
                      />
                      <SharedComponents.AppComponent.LabeledInput
                        label='Password'
                        onChange={val => setFieldValue('password', val)}
                        value={values.password}
                        inputProps={{
                          type: 'password'
                        }}
                      />
                      <Button
                        size='sm'
                        w='full'
                        mt='2'
                        onClick={handleSubmit}
                        isLoading={isSubmitting}
                      >
                        Create Account
                      </Button>
                    </VStack>
                  )}
                </Formik>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
