import {
  Box,
  Grid,
  HStack,
  Skeleton,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import SharedComponents from 'shared-components'
import { IoPlay } from 'react-icons/io5'
import { replace, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentMusic } from '../../../redux/slices/musicSlice'
import { FaPlay } from 'react-icons/fa6'

const MusicCard = data => {
  const [isMobile] = useMediaQuery('(max-width: 800px')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const appState_singer_id = useSelector(state => state.app.singer_id)

  const isCustomDomain = useSelector(state => state.app.isCustomDomain)
  const singer_id = useParams()?.singer_id || appState_singer_id

  function goToMusic () {
    if (isCustomDomain) {
      navigate(`music/${data.id}`)
    } else {
      navigate(`/${singer_id}/music/${data.id}`)
    }
  }

  return (
    <Grid
      h='max-content'
      w='full'
      gridTemplateColumns={'40px 1fr auto'}
      // gridTemplateRows={'repeat(2, 1fr)'}
      columnGap={'3'}
      bg='#15151E'
      _hover={{
        bg: '#323247'
      }}
      p='1.5'
      px='2'
      pr='4'
      borderRadius={'md'}
      cursor={'pointer'}
      onClick={goToMusic}
    >
      <SharedComponents.AppComponent.LazyImage_V2
        src={data?.imageUrl}
        h='40px'
        borderRadius='sm'
        alignSelf='center'
      />
      <VStack justify={'center'} align={'flex-start'} spacing={'-1'}>
        <Text fontWeight={'semibold'} fontSize={'16px'}>
          {data?.name}
        </Text>
        <Text fontSize='14px' textTransform={'capitalize'}>
          {data?.type === 'music' ? 'Single' : data?.type}
        </Text>
      </VStack>
      <HStack>
        <FaPlay color='gray.400' size='14px' />
        <Text alignSelf={'center'} color='gray.400' fontSize={'14px'}>
          {data?.streamCount}
        </Text>
      </HStack>
    </Grid>
  )
}

const MusicSkeleton = () => {
  return (
    <VStack
      role='group'
      _hover={{
        transform: 'scale(1.03)'
      }}
      transition={'all 300ms'}
      pos='relative'
      justify={'center'}
    >
      <Box
        transition={'all 200ms'}
        bg='#ffffff01'
        p='0.5'
        borderRadius={'md'}
        zIndex={'1'}
        boxShadow={'0px -3px 15px 0px rgba(0,0,0,0)'}
        _groupHover={{
          bg: 'white',
          boxShadow: '0px -3px 15px 0px rgba(0,0,0,0.5)'
        }}
      >
        <SharedComponents.AppComponent.LazyImage
          h='150px'
          w='140px'
          bg='gray'
          borderRadius={'md'}
          objectFit={'cover'}
          src={''}
        />
      </Box>
      <Skeleton h='17px' />
    </VStack>
  )
}

const MusicList = ({ currentSong }) => {
  const [isMobile] = useMediaQuery('(max-width: 800px)')
  const musicList = useSelector(state => state.music.list)
  const { song_id } = useParams()

  const _currentSong = currentSong || musicList?.find(x => x.id === song_id)

  function listData () {
    const list = musicList?.filter(x => x.id !== _currentSong?.id)
    let title = ''
    if (song_id) {
      if (currentSong?.type === 'music') {
        if (list.length > 0) {
          title = 'Other Releases'
        } else {
          title = 'No other releases'
        }
      } else {
        const _list = list?.filter(x => x.albumId === _currentSong?.id)
        title = _list?.length > 0 ? 'In this album' : ''
      }
    } else {
      title = 'All Releases'
    }
    return { title, list }
  }

  return (
    <VStack w='full' spacing={'0'} align={'flex-start'} px={isMobile && '2'}>
      <SharedComponents.AppComponent.Skeleton_V2
        isLoaded={musicList.length > 0}
      >
        <Text fontWeight={'semibold'} fontSize={'20px'} pb='3'>
          {listData().title}
        </Text>
      </SharedComponents.AppComponent.Skeleton_V2>
      {listData().list?.map(item => (
        <MusicCard {...item} key={`music_screen_music_card_${item.id}`} />
      ))}
    </VStack>
  )
}

export const HomeComponents = {
  MusicCard,
  MusicSkeleton,
  MusicList
}
