import { GoHome } from 'react-icons/go'
import LoginScreen from './screens/auth/login'
import AppPages from './screens/mainApp/pages'
import { CiCreditCard1 } from 'react-icons/ci'
import { GiTicket } from 'react-icons/gi'
import { BsMusicNote } from 'react-icons/bs'

const Routes = {
  MainApp: {
    screens: {
      Home: {
        label: 'Home',
        icon: <BsMusicNote size='17' />,
        index: true,
        element: <AppPages.Home />
      },
      Music: {
        label: 'Music',
        path: 'music/:song_id',
        element: <AppPages.Music />
      }
    }
  }
}

export default Routes

export function extractNestedRoutes (routeObject) {
  if (routeObject.screens && Object.keys(routeObject.screens).length > 0) {
    const nested_routes = []
    Object.keys(routeObject.screens).map(key => {
      const _routeSingle = routeObject.screens[key]
      nested_routes.push({ ..._routeSingle })
    })
    return nested_routes
  }

  return routeObject
}
